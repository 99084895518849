import * as React from "react";
import { Row, Col, Card, Divider, Typography, Button, Tooltip } from "antd";
import {
  FacebookFilled,
  RedditOutlined,
  TwitterOutlined,
  YoutubeFilled,
  InstagramFilled,
  MailOutlined,
  LinkedinFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Link } from "gatsby";
import HeaderComp from "../components/header";
import "../components/layout.css";
import FooterComp from "../components/footer";

const Blog = () => {
  return (
    <>
      <HeaderComp />
      <div className="enterpriseBanner">
        <Row className="blogTitleRow">
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <h1 className="enterpriseTitle">
              Why Omnichannel Is the Future of Customer Interaction?
            </h1>
          </Col>
        </Row>
      </div>
      <div className="blogSubDiv">
        <p>
          In a persistently connected world, consumers continue to set the pace
          for businesses. In response, brands and industries try to keep up with
          their requirements. However your customers expect choices and options
          to choose as to which form of service serves them right. They look
          forward to seamless experience which is consistent and hassle-free.
        </p>
        <p>
          Omnichannel gives your customers a completely integrated unified
          customer support experience with your brand across all platforms and
          channels.
        </p>
        <p>
          Today many service leaders are focussing on how to develop an
          effective approach that can deliver improved customer experience in a
          cost-effective way.
        </p>
        <p>
          <strong>
            <em>
              How Can You Use Omnichannel in Creating Strong Customer Base?
            </em>
          </strong>
        </p>
        <p>
          Omnichannel is about understanding customer behaviour. A successful
          Omnichannel approach not only provides a proper help desk platform but
          also streamlines multiple digital customer experiences within an
          integrated internal solutions infrastructure.
        </p>
        <p>
          <span className="order-management">
            <Link to="/" className="blogLink">
              <strong>CW Solutions </strong>
            </Link>
          </span>{" "}
          walks you through some of the techniques:
        </p>
        <ul className="BlogListUl">
          <li style={{ listStyleType: "disc" }}>
            Personalization is the key when talking about user engagement. To
            add to this multiple channel connectivity, logistical transparency
            and consistent brand messaging across all channels enhances the
            shopping experience of customers, which is definitely a positive
            trait.
          </li>
          <li style={{ listStyleType: "disc" }}>
            According to a customer no matter what channel he opts for he
            expects to be able to pick up from where he left off and not repeat
            the same process again. This highlights the true essence of seamless
            customer interaction through Omi Channel.
          </li>
          <li style={{ listStyleType: "disc" }}>
            A strategy is chalked out behind each digital touchpoint where all
            are bonded by more holistic experience.
          </li>
        </ul>
        <p>
          <strong>
            <em>How Can CW Solutions Help You in Your Endeavour?</em>
          </strong>
        </p>
        <p>
          The officials at CW Solutions have noted the importance and impact of
          customer empowerment on brands and business, hence they have come to
          the conclusion that companies must make{" "}
          <span className="order-management">
            <Link to="/" className="blogLink">
              <strong>Omni-channel </strong>
            </Link>
          </span>
          a part of their DNA in order to survive and thrive. We help you by:
        </p>
        <ul className="BlogListUl">
          <li style={{ listStyleType: "disc" }}>
            An Omni-Channel Engagement Centre
          </li>
          <li style={{ listStyleType: "disc" }}>
            Agent Desktop & Employee Engagement
          </li>
          <li style={{ listStyleType: "disc" }}>
            Actionable insights from existing data and turn them into useful
            business outcomes.
          </li>
        </ul>
        <p>
          For successful Omnichannel customer relations, it is imperative for
          all stakeholders in business including promotion, marketing, customer
          service, and sales – to deliver consistent and personalized messages
          across all channels.
        </p>
        <p>
          In order to have a strong online presence, every company develops an
          e-commerce website or an application. The portal should push the buyer
          towards making a purchase by providing online support.
        </p>
        <p>
          Thus an Omnichannel model that gives customers the finest aspects of
          traditional shopping – will be a key to the future of retail.
        </p>
        <Divider style={{ margin: "10px 0px" }} />
        <p style={{ marginBottom: 0, fontSize: "14px" }}>May 13th, 2020</p>
        <Divider style={{ margin: "10px 0px" }} />
        <Row className="platformRow">
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <h4 className="platformRowText">
              Share This Story, Choose Your Platform!
            </h4>
          </Col>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Row className="socialIconsRow">
              <div className="socialIconDiv">
                <Link
                  to="https://www.facebook.com/cwerpsolutions/"
                  target="_blank"
                >
                  <Tooltip title="Facebook">
                    <FacebookFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link to="https://twitter.com/CwerpSolutions" target="_blank">
                  <Tooltip title="Twitter">
                    <TwitterOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.linkedin.com/company/cwsolutions/"
                  target="_blank"
                >
                  <Tooltip title="Linkedin">
                    <LinkedinFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.linkedin.com/company/cwsolutions/"
                  target="_blank"
                >
                  <Tooltip title="Reddit">
                    <RedditOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.linkedin.com/company/cwsolutions/"
                  target="_blank"
                >
                  <Tooltip title="WhatsApp">
                    <WhatsAppOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link
                  to="https://www.youtube.com/channel/UC0wLykv1AkMd1ecUjRjbtwA"
                  target="_blank"
                >
                  <Tooltip title="Youtube">
                    <YoutubeFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link to="https://www.instagram.com/" target="_blank">
                  <Tooltip title="Instagram">
                    <InstagramFilled className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
              <div className="socialIconDiv">
                <Link to="https://www.instagram.com/" target="_blank">
                  <Tooltip title="Email">
                    <MailOutlined className="blogSocialIcons" />
                  </Tooltip>
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
      <FooterComp />
    </>
  );
};

export default Blog;
